import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

const Content = () => {
  return (
    <Box>
      <Typography
        variant='h4'
        color='textPrimary'
        gutterBottom
      >
        Definitions
      </Typography>

      <Typography paragraph>
        The following definitions apply to this Agreement:
      </Typography>
      <Typography paragraph>
        <strong>&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;:</strong>
        &nbsp; MangoMap Limited
      </Typography>
      <Typography paragraph>
        <strong>
          &quot;you&quot;, &quot;your&quot;, &quot;Customer&quot;:
        </strong>
        &nbsp; you, an individual or organization;
      </Typography>
      <Typography paragraph>
        <strong>&quot;Order Form&quot;</strong>: means an order form agreed and
        executed by both parties, setting out the Services ordered by the
        Customer or changes to the Services ordered by either Party;
      </Typography>
      <Typography paragraph>
        <strong>&quot;Services&quot;:</strong> means the services provided by
        MangoMap as set out in an Order Form and the service accessible at
        mangomap.com (https://www.mangomap.com);
      </Typography>
      <Typography paragraph>
        <strong>&quot;User Data&quot;</strong>: business data and other
        information related to you, your customers, or your business;
      </Typography>
      <Typography paragraph>
        <strong>&quot;Account&quot;</strong>: the access point that a Customer
        has to the Services, comprising a unique username (or email address) and
        a password;
      </Typography>
      <Typography paragraph>
        <strong>&quot;Subscription&quot;</strong>: the contract or agreement
        between the Customer and us, where a regular recurring fee is paid in
        exchange for access to the Services;
      </Typography>
      <Typography paragraph>
        <strong>&quot;Billing Cycle&quot;</strong>: refers to the recurring time
        period for which a Customer is billed for the use of the Services;
      </Typography>
      <Typography paragraph>
        <strong>“Standard Terms”</strong>: means these terms and conditions;
      </Typography>
      <Typography paragraph>
        If there is any conflict between the terms of this Agreement, the
        following order of priority shall apply:
      </Typography>
      <ol>
        <li>Order Form(s); and</li>
        <li>Standard Terms.</li>
      </ol>
      <Typography paragraph>
        To access the Services, Customers must at all times agree to and abide
        by these Terms. The Services allow you to submit, store, and access User
        Data. These Terms apply to all visitors, Customers and others who access
        or use the Service.
      </Typography>
      <Typography paragraph>
        By accessing or using the Services you agree to be bound by these Terms.
        If you disagree with any part of the terms then you may not access the
        Services.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Accounts
      </Typography>
      <Typography paragraph>
        When you create an account with us, you must provide us information that
        is accurate, complete, and current at all times. You must provide your
        legal full name, a valid email address, and any other information
        requested during the Account creation process. Failure to do so
        constitutes a breach of the Terms, which may result in immediate
        termination of your Account.
      </Typography>
      <Typography paragraph>
        You are responsible for safeguarding the password that you use to access
        the Services and for any activities or actions under your password,
        whether your password is with our Services or a third-party service.
        MangoMap Limited cannot and will not be liable for any loss or damage
        from your failure to comply with this security obligation.
      </Typography>
      <Typography paragraph>
        You agree not to disclose your password to any third party. You must
        notify us immediately upon becoming aware of any breach of security or
        unauthorized use of your account.
      </Typography>
      <Typography paragraph>
        You must be 13 years or older to create an Account using this Services,
        and you must be human. Accounts registered by “bots” or other automated
        methods are not permitted.
      </Typography>
      <Typography paragraph>
        Your login credentials may only be used by one person – a single Account
        shared by multiple people is not permitted.
      </Typography>
      <Typography paragraph>
        All User Data uploaded to the Services and activity that occurs under
        your Account (even when User Data is posted by others who have access to
        your account through additional login credentials) is solely your
        responsibility. The Service must not be used for any illegal or
        unauthorized purpose. You must not, in the use of the Service, violate
        any laws in your jurisdiction (including but not limited to copyright
        laws).
      </Typography>
      <Typography paragraph>
        You must not upload, post, host, or transmit unsolicited email, SMSs, or
        “spam” messages. You must not transmit any worms or viruses or any code
        of a destructive nature. You must not modify, adapt or hack the Services
        or modify another website so as to falsely imply that it is associated
        with the Services, MangoMap Limited, or any other Mango service.
      </Typography>
      <Typography paragraph>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit
        with malicious intent any portion of the Services, use of the Services,
        or access to the Services without the express written permission by
        MangoMap Limited.
      </Typography>
      <Typography paragraph>
        Verbal, physical, written or other abuse (including threats of abuse or
        retribution) of any customer, employee, member, or officer of MangoMap
        Limited will result in immediate Account termination.
      </Typography>
      <Typography paragraph>
        We may, but have no obligation to, terminate Accounts containing User
        Data that we determine in our sole discretion are unlawful, offensive,
        threatening, libelous, defamatory, pornographic, obscene or otherwise
        objectionable or violates any party’s intellectual property or these
        Terms of Services.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Partners
      </Typography>
      <Typography paragraph>
        A “Partner” is an individual or entity that has agreed to these Terms of
        Service and participates in the Mango Partner Program.
      </Typography>
      <ul>
        <li>
          The Partner will comply with all applicable laws and highest industry
          standards.
        </li>
        <li>
          The Partner must:
          <ol type='i'>
            <li>
              be at least 18 years old or the age of majority where Partner
              resides;
            </li>
            <li>
              provide certain Personal Information necessary for registration
              and authentication, and agree that email will be our primary
              method of communication;
            </li>
            <li>
              make certain acknowledgments if signing up on behalf of an
              employer or a company;
            </li>
            <li>
              agree that this Agreement may be changed by MangoMap Limited at
              any time.
            </li>
          </ol>
        </li>
      </ul>
      <Typography paragraph>
        Partner is responsible for compliance with this Agreement, including
        Terms of Services by Partner and any party Partner allows to access
        Partner Account.
      </Typography>
      <Typography paragraph>
        Partner is solely responsible for all User Data submitted to the
        Services, and Partner warrants and represents that you have the rights
        and authority to submit User Data on behalf of yourself and any
        third-party for which you submit User Data to the Services.
      </Typography>
      <Typography paragraph>
        You understand that by using the Services you may be exposed to User
        Data that might be offensive, harmful, inaccurate or otherwise
        inappropriate, or in some cases, postings that have been mislabeled or
        are otherwise deceptive. Under no circumstances will MangoMap Limited be
        liable in any way for any User Data, including, but not limited to, any
        errors or omissions in any User Data, or any loss or damage of any kind
        incurred as a result of the use of any User Data posted, emailed,
        transmitted or otherwise made available via the Services or broadcast
        elsewhere.
      </Typography>
      <Typography paragraph>MangoMap Limited reserves the right to:</Typography>
      <ul>
        <li>remove User Data without prior notice;</li>
        <li>
          refuse or terminate the Service to any person or entity for any
          reason, at our sole discretion;
        </li>
        <li>terminate Partner’s Account for violations of this Agreement.</li>
      </ul>
      <Typography paragraph>
        We do not pre-screen User Data, but we have the right (but not the
        obligation) to refuse or remove any User Data that is available via the
        Services.
      </Typography>
      <Typography paragraph>
        Partner is responsible for all activity that occurs under Partner’s
        Account. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of Partner’s Account.
      </Typography>
      <Typography paragraph>Partner may not:</Typography>
      <ul>
        <li>share Partner Account information;</li>
        <li>sublicense, resell, rent, or lease any portion of the Services;</li>
        <li>
          use the Services for illegal purposes or transmit illegal material;
        </li>
        <li>
          attempt to gain unauthorized access to, or disrupt the integrity,
          security or performance of the Services;
        </li>
        <li>
          knowingly send, upload, or distribute in any way any material that
          contains viruses, malware, or any other harmful software.
        </li>
      </ul>
      <Typography paragraph>Partner must:</Typography>
      <ul>
        <li>
          notify MangoMap Limited immediately of any unauthorized use of
          Partner’s Account or any other known breach of security.
        </li>
        <li>report User Data that violates our policies.</li>
        <li>
          not use any false information on Partner’s Account, create a false
          identity, or impersonate another person or entity in any way.
        </li>
      </ul>
      <Typography paragraph>
        We reserve the right to refuse service to anyone for any reason at any
        time.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Account Activation
      </Typography>
      <Typography paragraph>
        Subject to your compliance with these Terms of Service, MangoMap Limited
        grants you a personal, worldwide, non-exclusive, non-transferable,
        revocable, limited license to use and access the Services.
      </Typography>
      <Typography paragraph>
        You are responsible for maintaining the security of your account and
        password. MangoMap Limited cannot and will not be liable for any loss or
        damage from your failure to comply with this security obligation.
      </Typography>
      <Typography paragraph>
        You are responsible for all content posted and activity that occurs
        under your account (even when content is posted by others who have
        accounts under your account).
      </Typography>
      <Typography paragraph>
        You may not use the Service for any illegal or unauthorized purpose. You
        must not, in the use of the Service, violate any laws in your
        jurisdiction (including but not limited to copyright laws).
      </Typography>
      <Typography paragraph>
        You must not transmit any worms or viruses or any code of a destructive
        nature.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        General Conditions
      </Typography>
      <Typography paragraph>
        Your use of the Service is at your sole risk. The service is provided on
        an &quot;as is&quot; and &quot;as available&quot; basis.
      </Typography>
      <Typography paragraph>
        You must not modify, adapt or hack the Service or modify another website
        so as to falsely imply that it is associated with the Service, MangoMap
        Limited, or any other Mango service.
      </Typography>
      <Typography paragraph>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any
        portion of the Service, use of the Service, or access to the Service
        without the express written permission by MangoMap Limited.
      </Typography>
      <Typography paragraph>
        Verbal, physical, written or other abuse (including threats of abuse or
        retribution) of any MangoMap Limited customer, employee, member, or
        officer will result in immediate account termination.
      </Typography>
      <Typography paragraph>
        We may, but have no obligation to, remove content and accounts
        containing content that we determine in our sole discretion are
        unlawful, offensive, threatening, libelous, defamatory, pornographic,
        obscene or otherwise objectionable or violates any party&apos;s
        intellectual property or these Terms of Service.
      </Typography>
      <Typography paragraph>
        You understand that the technical processing and transmission of the
        Service, including your content, may be transferred unencrypted and
        involve (a) transmissions over various networks; and (b) changes to
        conform and adapt to technical requirements of connecting networks or
        devices.
      </Typography>
      <Typography paragraph>
        You must not upload, post, host, or transmit unsolicited email, SMSs, or
        &quot;spam&quot; messages.
      </Typography>
      <Typography paragraph>
        You must not transmit any worms or viruses or any code of a destructive
        nature.
      </Typography>
      <Typography paragraph>
        You expressly understand and agree that Mango shall not be liable for
        any direct, indirect, incidental, special, consequential or exemplary
        damages, including but not limited to, damages for loss of profits,
        goodwill, use, data or other intangible losses (even if Mango has been
        advised of the possibility of such damages), resulting from:
      </Typography>
      <ol type='i'>
        <li>the use or the inability to use the Services;</li>
        <li>
          the cost of procurement of substitute goods and services resulting
          from any goods, data, information or services purchased or obtained or
          messages received or transactions entered into through or from the
          Services;
        </li>
        <li>
          unauthorized access to or alteration of your transmissions or data;
        </li>
        <li>
          statements or conduct of any third party access through the Services;
        </li>
        <li>or any other matter relating to the Services.</li>
      </ol>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Disclaimer
      </Typography>
      <Typography paragraph>
        Your use of the Service is at your sole risk. The Service is provided on
        an “as is” and “as available” basis.
      </Typography>
      <Typography paragraph>
        The Service is provided without warranties of any kind, whether express
        or implied, including, but not limited to, implied warranties of
        merchantability, fitness for a particular purpose, non-infringement or
        course of performance.
      </Typography>
      <Typography paragraph>
        MangoMap Limited, its subsidiaries, affiliates, and its licensors do not
        warrant that
      </Typography>
      <ol type='i'>
        <li>the service will meet your specific requirements;</li>
        <li>
          the service will be uninterrupted, timely, secure, or error-free;
        </li>
        <li>
          the results that may be obtained from the use of the service will be
          accurate or reliable;
        </li>
        <li>
          the quality of any products, services, information, or other material
          purchased or obtained by you through the service will meet your
          expectations; and
        </li>
        <li>any errors in the Service will be corrected.</li>
      </ol>
      <Typography paragraph>
        While MangoMap Limited prohibits such conduct and User Data on the
        Service, you understand and agree that MangoMap Limited cannot be
        responsible for the User Data posted on the Service and you nonetheless
        may be exposed to such materials.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Copyright and Content Ownership
      </Typography>
      <Typography paragraph>
        We claim no intellectual property rights over the User Data or material
        you provide to the Service. Your Personal Information and User Data
        uploaded remain yours. However, by opting to make your User Data public
        and available to anonymous users through configuration of dataset access
        settings to a public state, you agree to allow others to view, access,
        or download your User Data without restriction. By opting to configure
        your maps as online and to be viewed publicly without access control,
        you agree to allow others to view your maps.
      </Typography>
      <Typography paragraph>
        Mango does not pre-screen User Data, but Mango and its designee have the
        right (but not the obligation) in their sole discretion to refuse or
        remove any User Data that is available via the Service.
        <br />
        <br />
        In addition, we are not a content-archiving service. We do not promise
        to store or make available on our Services any User Data that you post,
        or any other content, for any length of time. You are solely responsible
        for keeping back-ups of everything you post on our Services.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        {' '}
        Digital Millennium Copyright Act Compliance
      </Typography>
      <Typography paragraph>
        If you are a copyright owner or an agent thereof, and believe that any
        User Data or other material infringes upon your copyrights, you may
        submit a notification pursuant to the Digital Millennium Copyright Act
        (“DMCA”) by providing our Copyright Agent with the following information
        in writing (
        <Link
          href='http://www.copyright.gov/title17/92chap5.html#512'
          target='_blank'
        >
          17 U.S.C § 512(c)(3)
        </Link>{' '}
        for further detail):
      </Typography>
      <ul>
        <li>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed;
        </li>
        <li>
          Identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works on the Services are covered by a
          single notification, a representative list of such works from the
          Services;
        </li>
        <li>
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material;
        </li>
        <li>
          Information reasonably sufficient to permit us to contact the
          complaining party, such as an address, telephone number, and, if
          available, an electronic mail address;
        </li>
        <li>
          A statement that the complaining party has a good faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law; and
        </li>
        <li>
          A statement that the information in the notification is accurate, and
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of an exclusive right that is allegedly
          infringed.
        </li>
      </ul>
      <Typography paragraph>
        The designated Copyright Agent to receive notifications of claimed
        infringement is:
      </Typography>
      <Typography paragraph>
        MangoMap Limited,{' '}
        <Link
          href='mailto:support@mangomap.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          support@mangomap.com
        </Link>
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Fair Usage Policy
      </Typography>
      <Typography paragraph>
        Accounts can have up to 50,000 map views per month under our fair usage
        policy. Customers who think their maps will exceed this level regularly
        should contact Mango support.
      </Typography>
      <Typography paragraph>
        If your bandwidth usage significantly exceeds the average bandwidth
        usage (as determined solely by us) of others accessing of the Services,
        we reserve the right to immediately disable your account or throttle
        your file hosting until you can reduce your bandwidth consumption.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Waiver
      </Typography>
      <Typography paragraph>
        The failure of MangoMap Limited to exercise or enforce any right or
        provision of these Terms will not constitute a waiver of such right or
        provision. Any waiver of any provision of these Terms will be effective
        only if in writing and signed by an authorized representative of
        MangoMap Limited.
        <br />
        <br />
        If any provision of these Terms is held to be invalid or unenforceable
        by a court, the remaining provisions of these Terms will remain in
        effect. These Terms constitute the entire agreement between you and us
        and govern your use of the Services, superseding any prior agreements
        between you and us (including, but not limited to, any prior versions of
        these Terms of Services).
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Notice of modification
      </Typography>
      <Typography paragraph>
        MangoMap Limited reserves the right to update and change these Terms of
        Service from time to time without notice. Any new features that augment
        or enhance the current Services, including the release of new tools and
        resources, shall be subject to the Terms of Service. Continued use of
        the Service after any such changes shall constitute your consent to such
        changes. You can review the most current version of the Terms of Service
        at any time.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Indemnification
      </Typography>
      <Typography paragraph>
        You agree to indemnify and hold harmless MangoMap Limited and its
        respective directors, officers, employees and agents from and against
        any and all claims and expenses, including attorneys’ fees, arising out
        of your use of the Services, including but not limited to your violation
        of the Terms.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Support
      </Typography>
      <Typography paragraph>
        Our commitment to providing outstanding customer support is a
        cornerstone of our Services. Support requests can be submitted through
        our Help Centre which can be found at{' '}
        <a
          href='https://help.mangomap.com/hc'
          target='_blank'
          rel='noreferrer'
        >
          https://help.mangomap.com
        </a>
        .
      </Typography>
      <Typography paragraph>
        The expected response times for each of our plans are as follows:
      </Typography>
      <Typography paragraph>
        <strong>
          Tier 1:<span>&nbsp;</span>
        </strong>
        Our Agency plan customers enjoy our fastest support responses, with
        replies typically sent within 12 hours of the request submitted through
        the Mango Help Centre.
      </Typography>
      <Typography paragraph>
        <strong>
          Tier 2:<span>&nbsp;</span>
        </strong>
        As an Enterprise plan customer, you&apos;ll benefit from our priority
        support, and we aim to respond to your queries, submitted via the Mango
        Help Centre, within one business day.
      </Typography>
      <Typography paragraph>
        <strong>
          Tier 3:<span>&nbsp;</span>
        </strong>
        For our Business plan customers, we strive to respond to all support
        requests, submitted through the Mango Help Centre, within two business
        days.
      </Typography>
      <Typography paragraph>
        <strong>
          Tier 4:<span>&nbsp;</span>
        </strong>
        If you&apos;re on our Professional plan, you can expect to receive a
        response within three business days after submitting a support request
        via the Mango Help Centre.
      </Typography>
      <Typography paragraph>
        Please note that these times are maximum estimates and we always strive
        to respond to our customers as soon as possible.
      </Typography>
      <Typography paragraph>
        For our Enterprise and Agency plan customers, phone support is available
        by appointment. This allows us to ensure we provide timely and effective
        phone assistance. Unfortunately, phone support is currently not
        available for Professional and Business plans.
      </Typography>
      <Typography paragraph>
        These published response times apply to standard business days and
        hours, and might be affected by time zones, national holidays, and other
        events. We appreciate your understanding and are dedicated to assisting
        you as quickly and effectively as possible.
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Data Security
      </Typography>
      <Typography paragraph>
        You understand and accept that MangoMap Limited uses third party vendors
        and hosting partners to provide the necessary hardware, software,
        networking, storage, and related technology required to run the
        Services.
      </Typography>
      <Typography paragraph>
        You understand and accept that the technical processing and transmission
        of the Services, including your User Data, may be transferred
        unencrypted and involve
      </Typography>
      <ol type='i'>
        <li>transmissions over various networks; and</li>
        <li>
          changes to conform and adapt to technical requirements of connecting
          networks or devices.
        </li>
      </ol>
      <Typography paragraph>
        For further details about the Data Security processes in place at Mango,
        please see{' '}
        <Link
          href='/security'
          target='_self'
        >
          Data Security, Processing &amp; Storage
        </Link>
        .
      </Typography>
      <Typography
        variant={'h4'}
        color={'textPrimary'}
        gutterBottom
      >
        Contact
      </Typography>
      <Typography paragraph>
        If you have any questions, comments or requests regarding the Terms of
        Service, please email&nbsp;
        <Link
          href='mailto:support@mangomap.com'
          target='_blank'
        >
          support@mangomap.com
        </Link>
        .
      </Typography>
    </Box>
  )
}

export default Content
