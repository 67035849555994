import {
  Backdrop,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import * as React from 'react'

// Example items data
const items = [
  {
    image: './new-mangomap-assets/images/topographic.jpg',
    title: 'Mango for GIS',
    description:
      'A user-friendly, self-service web GIS platform that allows users to easily create, manage, and share interactive maps and data. Designed for users without specialized geospatial training, offering tools for visualizing and analyzing location-based information across various sectors.',
    link: '/sign-up',
    linkText: 'Get started'
  },
  {
    image: './new-mangomap-assets/images/tiles2.webp',
    title: 'Mango for Franchise Territory Mapping',
    description:
      'Accelerate growth with a comprehensive toolkit for franchisors to plan, sell, and manage territories. Simplify territory creations, analysis, market understanding, and network optimization with an intuitive interface, making spatial analytics accessible for franchise growth.',
    link: '/franchise',
    linkText: 'Learn more'
  }
]

const Products = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Product
      </Button>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      ></Backdrop>
      <Menu
        sx={{ top: '26px!important' }}
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        marginThreshold={0}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '100%',
            left: 0,
            right: 0
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onMouseLeave={handleClose}
      >
        <Box
          id='the menu box'
          m={8}
          sx={{ top: '88px' }}
        >
          <Grid
            container
            spacing={8}
          >
            {items.map((item, index) => (
              <Grid
                item
                sm={12}
                md={6}
                key={index}
              >
                <Card elevation={12}>
                  <CardMedia
                    component='img'
                    height='140'
                    image={item.image}
                    alt={item.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant='h4'
                      component='div'
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant='body1'
                      color='text.secondary'
                    >
                      {item.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      size='large'
                      variant='contained'
                      href={item.link}
                      target='_self'
                      endIcon={
                        <span className='material-symbols-rounded'>
                          arrow_forward
                        </span>
                      }
                    >
                      {item.linkText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Menu>
    </div>
  )
}

export default Products
