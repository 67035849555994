import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Image from 'next/image'

const Reviews = () => {
  const theme = useTheme()

  return (
    <Box>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
          gutterBottom
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={{ xs: 'column', sm: 'row' }}
          data-aos='fade-up'
        >
          It&rsquo;s no surprise GIS folk&nbsp;
          <span data-aos='zoom-in'>❤️</span>
          &nbsp;Mango
        </Box>
      </Box>
      <Grid
        container
        spacing={2}
      >
        {[
          {
            feedback:
              'The Mango interface is relatively light and easy to navigate and can be viewed either on desktop or mobile, making it useful to both our on-ground survey teams and the active public. One of our deciding factors was Mango’s consistent and excellent customer service that has always been responsive and collaborative.',
            name: 'Dr. Ceelia Leong',
            title: 'Senior Manager and Analytics Team Lead, Think City',
            avatar: '/new-mangomap-assets/images/thinkcity.png'
          },
          {
            feedback:
              'The platform met all project needs as it enables input data to be very easily viewed and understood by visitors.',
            name: 'Denise Minichelli Marçon',
            title:
              'Education Management for Sustainability and Citizenship, SESC',
            avatar: '/new-mangomap-assets/svg/sesc.svg'
          },
          {
            feedback:
              'We spent many years looking and waiting for a solution like Mango to help grow our business. Mango delivers all the functionality we (and our clients) need.',
            name: 'Phil Henry',
            title: 'Managing Director, Business Geographics',

            avatar: '/new-mangomap-assets/images/busgeo.png'
          },
          {
            feedback:
              'Once we load up Mango as an aid to discussing geo-spatial risk models, those “aha” moments inevitably cascade around the room.',
            name: 'Dion Oryzak',
            title: 'Director, Verisk Analytics',
            avatar: '/new-mangomap-assets/svg/verisk-logo.svg'
          },
          {
            feedback:
              'Mango does what we need it to do at a fraction of the cost of its competitors. A great way to serve up data for our company’s internal use. Customer support is quick & reliable.',
            name: 'Hope Kallio',
            title: 'GIS Specialist, Mostrom & Assoc., Inc.',
            avatar: '/new-mangomap-assets/svg/mostrom.svg'
          },
          {
            feedback:
              'Mango is a great out-of-the-box product that will allow you to get a professional product with minimal outlay of time and money.',
            name: 'Emily Selphe',
            title: 'Equalization Director, Charlevoix County',
            avatar: '/new-mangomap-assets/images/charlevoix.png'
          }
        ].map((item, i) => (
          <Grid
            item
            xs={10}
            sm={6}
            md={4}
            key={i}
            marginX={'auto'}
          >
            <Box
              bgcolor={'background.level1'}
              width={'100%'}
              height={'100%'}
              data-aos={'fade-up'}
              component={Card}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box component={CardContent}>
                <Box
                  marginBottom={1}
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    {[1, 2, 3, 4, 5].map((item) => (
                      <Box
                        key={item}
                        color={theme.palette.secondary.dark}
                      >
                        <svg
                          width={18}
                          height={18}
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                        >
                          <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
                        </svg>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    maxWidth={120}
                    width={80}
                    height={40}
                  >
                    <Image
                      alt={`${item.feedback} ${item.name} ${item.title}`}
                      src={item.avatar}
                      width={80}
                      height={11}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        width: 'auto'
                      }}
                    />
                  </Box>
                </Box>
                <Typography
                  component='p'
                  variant='subtitle2'
                  align={'left'}
                  fontSize={{ xs: '90%', sm: '100%' }}
                >
                  {item.feedback}
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <CardActions>
                <ListItem
                  component='div'
                  sx={{ padding: 1 }}
                >
                  <ListItemText
                    sx={{ margin: 0 }}
                    primary={item.name}
                    secondary={item.title}
                    secondaryTypographyProps={{ color: 'textSecondary' }}
                  />
                </ListItem>
              </CardActions>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Reviews
