import { PaletteOptions } from '@mui/material/styles/createPalette'

export const light: PaletteOptions = {
  alternate: {
    main: '#66800B',
    dark: '#8B7EC8'
  },
  mode: 'light',
  primary: {
    main: '#879A39',
    light: '#879A39',
    dark: '#0d47a1',
    contrastText: '#fff'
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#FF9800',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  text: {
    primary: '#100F0F',
    secondary: '#6F6E69'
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#FFFCF0',
    default: '#FFFCF0',
    level2: '#F2F0E5',
    level1: '#fff'
  }
}

export const dark: PaletteOptions = {
  alternate: {
    main: '#1C1B1A',
    dark: '#403E3C'
  },
  common: {
    black: '#000',
    white: '#fff'
  },
  mode: 'dark',
  primary: {
    main: '#205EA6',
    light: '#205EA6',
    dark: '#0d47a1',
    contrastText: '#fff'
  },
  secondary: {
    light: '#D0A215',
    main: '#BC5215',
    dark: '#AF3029',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4'
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#1C1B1A',
    default: '#1C1B1A',
    level2: '#333',
    level1: '#2D3748'
  }
}
