import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { RootState } from './store'

const initialState = {
  scroll: false,
  isPipedriveSuccess: false
}

export const websiteSlice = createSlice({
  name: 'website',
  initialState,
  reducers: {
    scrollTo: (state, action) => {
      state.scroll = action.payload
    },
    setIsPipedriveSuccess: (state, action) => {
      state.isPipedriveSuccess = action.payload
    }
  }
})

export const { scrollTo, setIsPipedriveSuccess } = websiteSlice.actions

export const useScroll = () =>
  useSelector((state: RootState) => state.website.scroll)
export const useIsPipedriveSuccess = () =>
  useSelector((state: RootState) => state.website.isPipedriveSuccess)

export default websiteSlice.reducer
