import { responsiveFontSizes } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import palette from './palette'
import shadows from './shadows'

const getTheme = (mode, paletteType) =>
  responsiveFontSizes(
    createTheme({
      palette: palette(mode, paletteType),
      shadows: shadows(mode),
      typography: {
        fontFamily: '"Inter", sans-serif',
        button: {
          textTransform: 'none',
          fontWeight: 'medium'
        },
        h1: {
          fontFamily: 'Gabarito',
          fontSize: '72px',
          fontWeight: 900,
          lineHeight: 1,
          letterSpacing: '-0.25px'
        },
        h3: {
          fontFamily: 'Gabarito'
        },
        h4: {
          fontFamily: 'Gabarito'
        },
        h6: {
          fontStyle: 'normal',
          fontWeight: 400,
          letterSpacing: '-0.7px',
          lineHeight: '1.4'
        }
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300
      }
    })
  )

export default getTheme

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    alternate?: PaletteColorOptions
    black?: PaletteColorOptions
    mangoRed?: PaletteColorOptions
    mangoOrange?: PaletteColorOptions
    mangoGreen?: PaletteColorOptions
  }
  interface TypeBackground {
    level2: string
    level1: string
    none?: string
  }
  interface TypeText {
    light?: string
  }
}
