import Box from '@mui/material/Box'
import Image from 'next/image'

const Partners = () => {
  return (
    <Box
      display='flex'
      flexWrap='wrap'
      justifyContent={'center'}
    >
      {[
        '/new-mangomap-assets/images/busgeo.png',
        '/new-mangomap-assets/svg/gis4ag.svg',
        '/new-mangomap-assets/images/ogl.png',
        '/new-mangomap-assets/svg/burgex.svg',
        '/new-mangomap-assets/images/ratio.png',
        '/new-mangomap-assets/images/digby.png',
        '/new-mangomap-assets/images/cestrat.png'
      ].map((item, i) => (
        <Box
          maxWidth={120}
          alignSelf={'center'}
          marginX={2}
          key={i}
        >
          <Image
            alt='...'
            src={item}
            width={120}
            height={120}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </Box>
      ))}
    </Box>
  )
}

export default Partners
