/* eslint-disable react/no-unescaped-entities */
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'next/image'

const Services = () => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  })
  const isXs = useMediaQuery(theme.breakpoints.up('xs'), {
    defaultMatches: true
  })

  return (
    <Box>
      <Grid
        container
        spacing={12}
      >
        <Grid
          item
          xs={12}
        >
          <Box marginBottom={{ xs: 4, sm: 8, md: 12 }}>
            <Typography
              variant='h6'
              component='h6'
              color='primary.light'
              align='center'
            >
              A NEW PARADIGM
            </Typography>
            <Box
              display='flex'
              alignItems='center'
              flexDirection={{ xs: 'column', lg: 'row' }}
              flexWrap='nowrap'
              alignContent='center'
              justifyContent='center'
            >
              <Typography
                fontWeight={700}
                variant={'h3'}
                align={'center'}
              >
                Share maps with just a&nbsp;
              </Typography>
              <span className='material-symbols-rounded material-symbols-h3'>
                ads_click
              </span>
              <Typography
                fontWeight={700}
                variant={'h3'}
                align={'center'}
              >
                &nbsp;click!
              </Typography>
            </Box>
            <Typography
              variant={'h5'}
              component={'p'}
              color={'textSecondary'}
              align={'center'}
              data-aos='fade-up'
            >
              Put maps in the hands that need them with precision access control
              for teams of any size.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction={isMd ? 'row' : 'column'}
      >
        <Grid
          item
          xs={12}
          sm={6}
          data-aos={isMd ? 'fade-right' : 'fade-up'}
        >
          <List>
            {[
              {
                icon: 'group_add',
                title: 'User-friendly team management.',
                subtitle:
                  'Control who sees or edits your maps with just a few clicks. Add or remove team members instantly, all without any extra costs.'
              },
              {
                icon: 'groups_3',
                title: 'Precision access controls.',
                subtitle:
                  'Precision privacy controls enable secure access for colleagues, clients, and stakeholders; or share your maps and data publicly on the web.'
              },
              {
                icon: 'globe',
                title: 'Global accessibility.',
                subtitle:
                  'Reach stakeholders across the globe and grant them access to precisely the maps and data they need\u2014no more, no less.'
              },
              {
                icon: 'vpn_lock',
                title: 'Maximum security, minimum effort.',
                subtitle:
                  '256-bit encryption and best in class data center security, combined with stringent access controls, and disaster recovery measures to safeguard your data, ensuring the highest level of protection.'
              }
            ].map((item, index) => (
              <ListItem
                disableGutters
                key={index}
                data-aos='fade-up'
                alignItems={'flex-start'}
              >
                <ListItemAvatar sx={{ width: '50px!important' }}>
                  <Typography
                    component={Avatar}
                    color={theme.palette.primary.contrastText}
                    bgcolor={`${theme.palette.primary.main}`}
                  >
                    <span className='material-symbols-rounded material-symbols-h4'>
                      {item.icon}
                    </span>
                  </Typography>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant='h4'
                      component={'p'}
                      color='text.primary'
                    >
                      {item.title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant='h6'
                      component={'p'}
                      color='text.secondary'
                    >
                      {item.subtitle}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid
          item
          justifyContent='center'
          alignItems='center'
          xs={12}
          sm={6}
          data-aos={isMd ? 'fade-left' : 'fade-up'}
          maxHeight='600px'
          minHeight='200px'
          sx={{
            marginLeft: { xs: 2, md: 'unset' },
            marginTop: { xs: 2, md: 'unset' }
          }}
        >
          <Image
            quality={75}
            priority
            src={'/new-mangomap-assets/images/home_team.jpg'}
            alt='Put maps in the hands that need them with precision access control for teams of any size.'
            fill
            style={{
              objectFit: 'cover',
              borderRadius: '30px'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Services
