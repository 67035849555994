import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Container from '~gis-website/common/Container'
import MangoLogo from '~gis-website/svg/logos/MangoLogo'
import { Confirmation } from './components'

import Main from '~gis-website/layouts/Main'
import WithLayout from '~gis-website/WithLayout'

const Confirm = () => {
  return (
    <WithLayout
      component={App}
      layout={Main}
    />
  )
}

const App = () => {
  return (
    <Container
      minHeight={'100vh'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
      >
        <Link href='/'>
          <Box
            alignItems='center'
            title='Mango GIS'
            width={200}
            marginRight={1}
          >
            <MangoLogo />
          </Box>
        </Link>
      </Box>
      <Grid
        container
        display={'flex'}
        flexDirection={'row'}
        alignItems={'flex-start'}
        justifyContent={'space-around'}
      >
        <Grid
          item
          xs={12}
          display={'flex'}
          alignContent={'flex-end'}
        >
          <Confirmation />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Confirm
