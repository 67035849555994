import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import WithLayout from '~gis-website/WithLayout'
import Container from '~gis-website/common/Container'
import Minimal from '~gis-website/layouts/Minimal'
import MangoLogo from '~gis-website/svg/logos/MangoLogo'
import { Form } from './components'

const SignIn = () => {
  return (
    <WithLayout
      component={App}
      layout={Minimal}
    />
  )
}

const App = () => {
  return (
    <Container
      minHeight={'100dvh'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Box>
        <Link href='/'>
          <Box
            alignItems='center'
            title='Mango GIS'
            width={200}
          >
            <MangoLogo />
          </Box>
        </Link>
      </Box>
      <Box
        position={'relative'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Container maxWidth={600}>
          <Form />
        </Container>
      </Box>
    </Container>
  )
}

export default SignIn
