import GTM from 'react-gtm-module'

const TAG_MANAGER_ARGS = {
  gtmId: process.env.GOOGLE_TAG_MANAGER_ID, // e.g. 'GTM-T2V4CX6',
  events: {
    submit_contact_form: 'submit_contact_form'
  }
}

const TagManager = {
  init() {
    GTM.initialize(TAG_MANAGER_ARGS)
  },

  dataLayer: {
    submitContactForm() {
      GTM.dataLayer({
        dataLayer: {
          event: 'form_submit'
        }
      })
    }
  }
}

export { TAG_MANAGER_ARGS, TagManager }
