import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

const FranchiseCTA = () => {
  return (
    <Container>
      <Box
        component={Card}
        data-aos={'fade-up'}
        borderRadius={4}
        boxShadow={4}
        sx={{
          backdropFilter: 'blur(4px)',
          textAlign: 'center'
        }}
      >
        <Box
          component={CardContent}
          padding={4}
          width={'100%'}
          height={'100%'}
        >
          <Typography
            component={Typography}
            variant={'h5'}
            gutterBottom
            fontWeight={500}
            align='center'
          >
            Mango for Franchise Territory Mapping
          </Typography>
          <Typography
            color='text.secondary'
            align='center'
          >
            The prices listed on this page are for our general, self-serve
            solution. For franchise territory mapping, please&nbsp;
            <Link
              href='/franchise'
              target='blank'
            >
              click here
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default FranchiseCTA
