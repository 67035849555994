export const pages = [
  {
    title: 'Mangomap',
    id: 'landing-pages',
    marker: 'home_pin',
    pages: [
      {
        title: 'Mango for GIS',
        href: '/',
        icon: '',
        target: 'self'
      },
      {
        title: 'Mango for Franchise',
        href: '/franchise',
        icon: '',
        target: 'self'
      }
    ]
  },
  {
    title: 'Resources',
    id: 'resource-pages',
    marker: 'navigation',
    pages: [
      {
        title: 'Pricing',
        href: '/pricing',
        icon: '',
        target: 'self'
      },
      {
        title: 'Help Centre',
        href: 'https://help.mangomap.com',
        icon: '',
        target: 'blank'
      }
    ]
  },
  {
    title: 'Get Started with Mango',
    id: 'auth-pages',
    marker: 'person_pin_circle',
    pages: [
      {
        title: 'Sign in',
        href: '/sign-in',
        icon: 'waving_hand',
        target: 'self',
        important: true
      },
      {
        title: 'Sign up',
        href: '/sign-up',
        icon: 'arrow_forward',
        target: 'self',
        important: true
      }
    ]
  }
]
