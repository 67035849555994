const MangoIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
    >
      <path
        d='M31.822 63.692C49.3968 63.692 63.644 49.4341 63.644 31.846C63.644 14.2579 49.3968 0 31.822 0C14.2472 0 0 14.2579 0 31.846C0 49.4341 14.2472 63.692 31.822 63.692Z'
        fill='#FF5722'
      />
      <path
        d='M21.974 53.763C34.1099 53.763 43.948 43.9379 43.948 31.818C43.948 19.6982 34.1099 9.87305 21.974 9.87305C9.8381 9.87305 0 19.6982 0 31.818C0 43.9379 9.8381 53.763 21.974 53.763Z'
        fill='#FF9800'
      />
      <path
        d='M60.484 17.979C59.603 17.979 58.788 17.937 56.259 18.312C51.069 19.083 46.459 21.185 42.413 24.098C27.173 35.076 19.905 57.172 19.298 59.092C19.278 59.148 19.273 59.168 19.267 59.187C19.263 59.199 19.253 59.23 19.253 59.23C19.253 59.23 19.263 59.203 19.263 59.197C19.271 59.176 19.275 59.162 19.292 59.108C19.991 56.712 30.26 20.278 5.59501 18.05C4.82401 17.977 4.02501 17.939 3.18301 17.937C1.14801 22.131 0.00400758 26.839 7.58189e-06 31.815C-0.0119924 49.404 14.222 63.677 31.8 63.691C49.372 63.705 63.633 49.457 63.645 31.865C63.648 26.892 62.513 22.182 60.484 17.979Z'
        fill='url(#paint0_linear_1618_2)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1618_2'
          x1='7.58189e-06'
          y1='40.8145'
          x2='63.6445'
          y2='40.8145'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8BC34A' />
          <stop
            offset='0.3929'
            stopColor='#79B041'
          />
          <stop
            offset='1'
            stopColor='#558B2F'
          />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MangoIcon
