/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import * as yup from 'yup'

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please enter your first name.'),
  lastName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please enter your last name.'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('We need a valid email address. You’ll sign in with this email.'),
  account: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('An account name is required. This will form your account URL.'),
  password: yup
    .string()
    .trim()
    .required('Please specify your password')
    .matches(
      /^[^{}|[\]:"<>?/\\`~!@#$%^&*()+=]+$/,
      'Password cannot contain symbols except - and _'
    )
    .min(6, 'Passwords must be at least 6 characters.')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-zA-Z]/, 'Password requires a letter')

    .matches(/^[^\s]*$/, 'Password cannot contain a space')
})

const SignUpForm = () => {
  const router = useRouter()
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    account: ''
  }

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await fetch('/clients/create_new_account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      })

      if (!response.ok) {
        const errorData = await response.json()
        throw errorData
      }
      router.push('/confirm')
    } catch (error: any) {
      setErrors(error.data)
    } finally {
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit
  })

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          sx={{
            '& .MuiInputBase-input.MuiOutlinedInput-input': {
              bgcolor: 'background.level1'
            }
          }}
          display={'flex'}
          alignItems={'flex-end'}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              component='h3'
              color='textSecondary'
              sx={{ marginBottom: 1 }}
            >
              Work email
            </Typography>
            <TextField
              label='Work email *'
              variant='outlined'
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Typography
              variant={'caption'}
              color={'textSecondary'}
            >
              You'll sign in to your account with this email.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              component={'h3'}
              color='textSecondary'
              sx={{ marginBottom: 1 }}
            >
              Set a password
            </Typography>
            <TextField
              label='Password *'
              variant='outlined'
              name={'password'}
              type={'password'}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />{' '}
            <Typography
              variant={'caption'}
              color={'textSecondary'}
            >
              6 characters minimum, alphanumeric, underscores and dashes only
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Typography
              component={'h3'}
              color='textSecondary'
              sx={{ marginBottom: 1 }}
            >
              Your name
            </Typography>
            <TextField
              label='First name *'
              variant='outlined'
              name={'firstName'}
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              label='Last name *'
              variant='outlined'
              name={'lastName'}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              component={'h3'}
              color='textSecondary'
              sx={{ marginBottom: 1 }}
            >
              Set an account name
            </Typography>

            <TextField
              label='Account name *'
              variant='outlined'
              name={'account'}
              fullWidth
              value={formik.values.account}
              onChange={formik.handleChange}
              error={formik.touched.account && Boolean(formik.errors.account)}
              helperText={formik.touched.account && formik.errors.account}
            />
            <Typography
              variant={'caption'}
              color={'textSecondary'}
            >
              You can change this later.
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
          >
            <Box
              display='flex'
              flexDirection={{ xs: 'column-reverse', sm: 'row' }}
              alignItems={{ xs: 'stretch', sm: 'center' }}
              justifyContent={'space-between'}
              width={'100%'}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography
                  variant={'subtitle2'}
                  align={'center'}
                >
                  Already have an account?{' '}
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'/sign-in'}
                    underline={'none'}
                  >
                    Sign in
                  </Link>
                </Typography>
              </Box>
              <Button
                size={'large'}
                variant={'contained'}
                type={'submit'}
              >
                Start trial
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              variant={'subtitle2'}
              color={'textSecondary'}
              align={'center'}
            >
              By clicking "Start trial" button you agree with our{' '}
              <Link
                component={'a'}
                color={'primary'}
                href={'/privacy'}
                target={'blank'}
                underline={'none'}
              >
                Privacy policy
              </Link>{' '}
              and{' '}
              <Link
                component={'a'}
                color={'primary'}
                href={'/terms'}
                target={'blank'}
                underline={'none'}
              >
                Terms
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default SignUpForm
