import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
{
  /* import ExpandMoreIcon from '@mui/icons-material/ExpandMore';*/
}

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme()
  return (
    <Box>
      <Box marginBottom={4}>
        <Box
          component={Typography}
          fontWeight={700}
          variant={'h3'}
          align={'center'}
        >
          {title}
        </Box>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            bgcolor={'transparent'}
            component={Accordion}
            key={i}
            padding={1}
            elevation={3}
            sx={{
              '&:first-of-type': {
                borderTopLeftRadius: theme.spacing(1),
                borderTopRightRadius: theme.spacing(1)
              },
              '&:not(:first-of-type):before': {
                opacity: '0 !important',
                display: 'block !important'
              },
              '&.Mui-expanded': {
                marginY: 2,
                backdropFilter: 'blur(4px)'
              }
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={
                <span className='material-symbols-rounded'>expand_more</span>
              }
              aria-controls='panel1a-content'
              id={`panel1a-header--${i}`}
              padding={`${theme.spacing(0)} !important`}
            >
              <Typography
                variant={'h4'}
                fontWeight={600}
              >
                {item.title}
              </Typography>
            </Box>
            <AccordionDetails>
              <Typography
                variant={'h6'}
                color='text.secondary'
              >
                {item.subtitle}
              </Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const Content = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignContent={'center'}
      marginX={'auto'}
    >
      <Box marginBottom={6}>
        <FaqGroupItem
          title={'Frequently asked questions'}
          items={[
            {
              title: 'Can I try Mango for free?',
              subtitle: 'Yes. No credit card required, no strings attached.'
            },
            {
              title: 'Is the trial account limited in any way?',
              subtitle:
                'No. Your trial includes the whole kitchen sink: a fully functional Mango account on our Enterprise plan with all tools and add-ons available — upload, style and visualise your spatial data with vector, raster, and WMS support; deploy maps with our full suite of GIS analysis tools and share them with the world, or just your team.'
            },
            {
              title: 'Who can access my maps?',
              subtitle:
                'Mango offers precision access control, ensuring your maps are only accessible to your intended audience.'
            },
            {
              title: 'Who can access my data?',
              subtitle:
                'Data uploaded to Mango are private by default. You are in complete control of who can view or update your data with advanced sharing options.'
            },
            {
              title: 'Where are my maps and data stored?',
              subtitle:
                'We use Amazon Web Services us-east region for all infrastructure. Map tiles generated from your data are served by a fast content delivery network with worldwide distribution points to maximise delivery speeds — no matter where your map users are located.'
            },
            {
              title: 'What happens to my data after the trial?',
              subtitle:
                'If you choose not to continue with a paid subscription, all maps and data are irrevocably deleted.'
            },
            {
              title: 'Can I invite my team during the trial?',
              subtitle:
                'Yes, invite colleagues and stakeholders as viewers, admins, or editors, and securely control access to ensure your maps and data can only be accessed by authorised users.'
            },
            {
              title:
                'Our procurement process is longer than 30 days - can I extend my trial?',
              subtitle:
                'We understand that every procurement varies. We can tailor procurements to your needs, ensuring the maps you created in Mango remain available throughout the process.'
            },
            {
              title: 'Do I have to sign a long term contract?',
              subtitle:
                'Mango is a pay-as-you-go subscription service, billed monthly or yearly. If you need a longer term solution, we offer generous additional discounts for multi-year billing options.'
            },
            {
              title: 'Can I switch plans?',
              subtitle:
                'Yes, you can change anytime during your trial. On a paid plan, plan changes are immediate and are charged pro-rata. Downgrades credit any unused payments to your account and applied to future renewals.'
            },
            {
              title: 'Do you offer non-profit discounts?',
              subtitle:
                'Yes. Contact us to discuss your non-profit mapping needs.'
            },
            {
              title: 'Does pricing include taxes such as UK or EU VAT?',
              subtitle:
                'All pricing indicated above are exclusive of taxes. Customers in the UK will be charged 20% VAT on all plans and services. Customers in the EU can be issued VAT invoices using the reverse charge mechanism on request to account for VAT.'
            },
            {
              title: 'What credit cards do you accept?',
              subtitle:
                'We accept Visa, Mastercard, and American Express. Payments are processed via Stripe so you can be confident your transaction is secure.'
            },
            {
              title: 'Can I pay by invoice?',
              subtitle:
                'Yes, we accept bank transfer for annual and multi year subscriptions.'
            },
            {
              title: 'Is there a money-back guarantee?',
              subtitle: 'Yes. 60 day, no questions asked, money-back guarantee.'
            },
            {
              title: 'Do you take feature requests?',
              subtitle:
                'Many current Mango features were driven by your feedback. We have a feature roadmap that guides Mango development, but we are always happy to hear how our customers are using Mango and how we can help solve our customers problems.'
            }
          ]}
        />
      </Box>
    </Box>
  )
}

export default Content
