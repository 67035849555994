import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Slide from '@mui/material/Slide'
import { useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useState } from 'react'
import Container from '~gis-website/common/Container'
import { pages } from '../navigation'
import { Footer, Sidebar, Topbar } from './components'

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger()

  return (
    <Slide
      appear={false}
      direction='down'
      in={!trigger}
    >
      {children}
    </Slide>
  )
}

const Main = ({
  children,
  themeToggler,
  themeMode,
  setThemePalette,
  paletteType
}) => {
  const theme = useTheme()
  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  return (
    <div>
      <HideOnScroll>
        <AppBar
          enableColorOnDark
          position={'fixed'}
          sx={{
            backgroundColor: theme.palette.background.paper
          }}
          elevation={5}
        >
          <Container
            paddingY={{ xs: 1 / 2, sm: 1 }}
            maxWidth={'100%'}
          >
            <Topbar onSidebarOpen={handleSidebarOpen} />
          </Container>
        </AppBar>
      </HideOnScroll>
      <Sidebar
        onClose={handleSidebarClose}
        open={openSidebar}
        variant='temporary'
        pages={pages}
      />
      <main>
        <Box height={{ xs: 56, sm: 64 }} />
        {children}
        <Divider />
      </main>
      <Container
        paddingY={4}
        name='Main Footer'
        position='relative'
        zIndex='1'
      >
        <Footer />
      </Container>
    </div>
  )
}

export default Main
