/* eslint-disable react/no-unescaped-entities */
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Image from 'next/image'

const Features = () => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  })

  return (
    <Box>
      <Grid
        container
        spacing={12}
      >
        <Grid
          item
          xs={12}
        >
          <Box marginBottom={{ xs: 4, sm: 8, md: 12 }}>
            <Typography
              variant='h6'
              color='primary.light'
              align='center'
            >
              MAP PUBLICATION MADE EASY
            </Typography>
            <Box
              display='flex'
              alignItems='center'
              flexDirection={{ xs: 'column', md: 'row' }}
              alignContent='center'
              justifyContent='center'
              data-aos='fade-up'
            >
              <Typography
                fontWeight={700}
                variant={'h3'}
                align={'right'}
              >
                Deliver&nbsp;
              </Typography>
              <span className='material-symbols-rounded material-symbols-h3'>
                ar_on_you
              </span>
              <Typography
                fontWeight={700}
                variant={'h3'}
                data-aos='fade-up'
              >
                &nbsp;user-&#8203;focused maps
              </Typography>
            </Box>
            <Typography
              variant={'h5'}
              component={'p'}
              color={'textSecondary'}
              align={'center'}
              data-aos='fade-up'
            >
              Deliver map visualizations and spatial analysis tools for both GIS
              professionals and end-users.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          justifyContent='center'
          alignItems='center'
          xs={12}
          sm={6}
          data-aos={isMd ? 'fade-right' : 'fade-up'}
          maxHeight='600px'
          minHeight='200px'
          sx={{
            marginLeft: { xs: 2, md: 'unset' },
            marginTop: { xs: 2, md: 'unset' }
          }}
        >
          <Image
            quality={75}
            priority
            src={'/new-mangomap-assets/images/home_tablet.jpg'}
            alt='Deliver map visualizations and spatial analysis tools for both GIS professionals and end-users.'
            fill
            style={{
              objectFit: 'cover',
              borderRadius: '30px',
              margin: 0
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          data-aos={isMd ? 'fade-right' : 'fade-up'}
        >
          <List disablePadding>
            {[
              {
                icon: 'manage_search',
                title: 'Comprehensive GIS Tools.',
                subtitle:
                  'An extensive array of GIS tools and customization options, giving you full control over your maps and data.'
              },

              {
                icon: 'beenhere',
                title: 'Unlock Your GIS Data Potential.',
                subtitle:
                  'Effortlessly transform your GIS data into captivating services that delight your clients and boost your profitability.'
              },
              {
                icon: 'published_with_changes',
                title: 'Publish reliably.',
                subtitle:
                  'Our cutting-edge cloud infrastructure ensures lightning-fast speed and unmatched reliability. 24/7 monitoring and robust fail-over systems guarantee industry-leading availability.'
              },
              {
                icon: 'request_quote',
                title: 'Transparent Pricing.',
                subtitle:
                  'No complicated credits or quotas. Our pricing is competitive, straightforward, and predictable, so you always know what to expect.'
              }
            ].map((item, index) => (
              <ListItem
                disableGutters
                key={index}
                data-aos='fade-up'
                alignItems={'flex-start'}
              >
                <ListItemAvatar>
                  <Typography
                    component={Avatar}
                    color={theme.palette.primary.contrastText}
                    bgcolor={`${theme.palette.primary.main}`}
                  >
                    <span className='material-symbols-rounded material-symbols-h4'>
                      {item.icon}
                    </span>
                  </Typography>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant='h4'
                      component={'p'}
                      color='text.primary'
                    >
                      {item.title}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant='h6'
                      component={'p'}
                      color='text.secondary'
                    >
                      {item.subtitle}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Features
