import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { withReduxConfigs } from 'utils'
import websiteReducer from './'

const combinedReducers = combineReducers({
  website: websiteReducer
})

const store = configureStore(
  withReduxConfigs({
    reducer: combinedReducers
  })
)

export type RootState = ReturnType<typeof combinedReducers>
export { store }
