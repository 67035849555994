import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const ContactCard = () => {
  const theme = useTheme()

  return (
    <Box
      component={Card}
      boxShadow={0}
      border={{
        xs: 0,
        md: `1px solid ${theme.palette.divider}`
      }}
    >
      <Box
        paddingX={{ xs: 0, md: 3 }}
        paddingTop={3}
        paddingBottom={{ xs: 0, md: 3 }}
      >
        <Typography
          sx={{
            fontWeight: '700'
          }}
          gutterBottom
        >
          How can you contact us about this notice?
        </Typography>
        <Typography
          variant={'body2'}
          color={'textSecondary'}
          sx={{
            marginBottom: 2
          }}
        >
          If you have any questions or concerns about the terms of service
          please contact us.
        </Typography>
        <Typography variant={'subtitle2'}>
          support@mangomap.com
          <br />
          1 Victoria Street
          <br />
          Bristol, BS1 6AA
          <br />
          United Kingdom
        </Typography>
      </Box>
    </Box>
  )
}

export default ContactCard
