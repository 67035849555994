import { Box, Typography } from '@mui/material'

const AppError = ({ alt, src, title, description }) => {
  return (
    <Box
      data-testid='AppError'
      height='100vh'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      px={4}
      gap={2}
      textAlign='center'
    >
      <Box
        component='img'
        src={src}
        sx={{ width: { xs: '200px', md: '300px' } }}
        alt={alt}
      />
      {!!title && <Typography variant='h6'>{title}</Typography>}
      {!!description && (
        <Typography
          variant='body2'
          color='GrayText'
        >
          {description}
        </Typography>
      )}
    </Box>
  )
}

export default AppError
