/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Image from 'next/image'
import Slider from 'react-slick'
import { SignUpButton } from '../../../globals'

const Hero = () => {
  const theme = useTheme()

  const Partners = () => (
    <Box
      display={'flex'}
      flexDirection={'row'}
      flexWrap={'wrap'}
      justifyContent={'space-around'}
    >
      {[
        '/new-mangomap-assets/images/sensar.png',
        '/new-mangomap-assets/svg/gis4ag.svg',
        '/new-mangomap-assets/images/geotech-info.png',
        '/new-mangomap-assets/svg/enerko.svg',
        '/new-mangomap-assets/svg/swift.svg',
        '/new-mangomap-assets/svg/gbi.svg',
        '/new-mangomap-assets/images/plumas.png'
      ].map((item, i) => (
        <Box
          maxWidth={120}
          width={{ xs: 110, lg: 100 }}
          alignSelf={'center'}
          justifyContent={'space-around'}
          marginX={3}
          marginTop={2}
          key={i}
        >
          <Image
            alt='GIS companies worldwide use Mango'
            src={item}
            width={120}
            height={120}
            style={{
              height: 'auto',
              filter: 'grayscale(1) opacity(.5)'
            }}
          />
        </Box>
      ))}
    </Box>
  )

  const HeroTestimonials = () => {
    const sliderOpts = {
      arrows: false,
      className: 'center',
      centerMode: true,
      centerPadding: '60px',
      infinite: true,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      cssEase: 'ease-in-out',
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 424,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerPadding: '10px'
          }
        }
      ]
    }

    return (
      <Box>
        <Slider {...sliderOpts}>
          {[
            {
              feedback:
                'Mango does what we need it to do, at a fraction of the cost of its competitors.',
              image: '/new-mangomap-assets/svg/mostrom.svg',
              name: 'Hope Kallio',
              title: 'GIS Specialist at Mostrom & Associates'
            },
            {
              feedback:
                'I like the analogy that Mango use: sometimes it’s better to use a scalpel than a swiss army knife.',
              name: 'Phil Henry',
              title: 'Business Geographics & Qikmaps',
              image: '/new-mangomap-assets/images/busgeo.png'
            },
            {
              feedback:
                'Easy to setup, GREAT support team that responded quickly early on and then the tool itself was very easy to use and produced desired results. This is how software should be. Legit!',
              name: 'Dave Ranallo',
              title: 'Balanced Family Academy',
              image: 'new-mangomap-assets/svg/bfa.svg'
            },
            {
              feedback:
                'Since implementing Mango into our GIS department’s website, we went from ‘basic and boring’... to ‘WOW!’',
              name: 'Becky Osborn',
              title: 'GIS Planner at Plumas County, CA',
              image: '/new-mangomap-assets/images/plumas.png'
            },
            {
              feedback:
                'Love Mango... compared to ArcGIS online, it provides better visual mapping and a straightforward pricing model.',
              name: 'Dr. Chris Green',
              title: 'Snr Epidemiologist, WRHA',
              image: '/new-mangomap-assets/svg/wrha.svg'
            }
          ].map((item, i) => (
            <Box
              key={i}
              paddingX={{ xs: 1 / 2, sm: 1, md: 4 }}
              paddingY={{ xs: 1, sm: 2, md: 4 }}
            >
              <Box
                bgcolor={'background.level1'}
                width={'100%'}
                height={'100%'}
                data-aos={'fade-up'}
                component={Card}
                display={'flex'}
                flexDirection={'column'}
              >
                <Box component={CardContent}>
                  <Box
                    marginBottom={1}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                    >
                      {[1, 2, 3, 4, 5].map((item) => (
                        <Box
                          key={item}
                          color={theme.palette.secondary.dark}
                        >
                          <svg
                            width={18}
                            height={18}
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                          >
                            <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
                          </svg>
                        </Box>
                      ))}
                    </Box>
                    <Box
                      maxWidth={120}
                      width={80}
                      height={40}
                    >
                      <Image
                        alt={`${item.feedback} ${item.name} ${item.title}`}
                        src={item.image}
                        width={80}
                        height={11}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          width: 'auto'
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography
                    component='p'
                    variant='body2'
                    align={'left'}
                    fontSize={{ xs: '90%', sm: '100%' }}
                  >
                    {item.feedback}
                  </Typography>
                </Box>

                <CardActions>
                  <ListItem
                    component='div'
                    sx={{ padding: 1 }}
                  >
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary={item.name}
                      secondary={item.title}
                      secondaryTypographyProps={{ color: 'textSecondary' }}
                    />
                  </ListItem>
                </CardActions>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    )
  }

  return (
    <Container maxWidth={false}>
      <Container>
        <Box
          marginBottom={2}
          data-aos={'fade-up'}
        >
          <Typography
            variant='h6'
            color='primary.light'
            align='center'
          >
            ONLINE GIS PLATFORM
          </Typography>
        </Box>
        <Box
          marginBottom={2}
          data-aos={'fade-up'}
        >
          <Typography
            variant='h1'
            color='textPrimary'
            gutterBottom
            align='center'
            sx={{
              fontWeight: 700,
              marginBottom: 2
            }}
          >
            GIS mapping, made simple.
          </Typography>
          <Typography
            variant='h6'
            component='p'
            color='textSecondary'
            align='center'
          >
            The accessible and user-friendly alternative to Esri for smart
            spenders.
          </Typography>
        </Box>
      </Container>
      <Container maxWidth='lg'>
        <HeroTestimonials />
      </Container>
      <Grid
        display={'flex'}
        flexDirection={'column'}
        alignContent={'center'}
      >
        <Grid
          item
          xs={6}
        >
          <Container maxWidth='xs'>
            <SignUpButton />
            <Box
              display={'flex'}
              alignContent={'center'}
              justifyContent={'space-evenly'}
            >
              <Box
                display='flex'
                flexWrap='wrap'
                flexDirection='row'
                alignItems='center'
              >
                <span className='material-symbols-rounded material-symbols-h6'>
                  check
                </span>
                <Typography
                  variant='caption'
                  align='center'
                  component='p'
                >
                  30-DAY FREE TRIAL
                </Typography>
              </Box>
              <Box
                display='flex'
                flexWrap='wrap'
                flexDirection='row'
                alignItems='center'
                paddingY={1}
              >
                <span className='material-symbols-rounded material-symbols-h6'>
                  check
                </span>
                <Typography
                  variant='caption'
                  align='center'
                  component='p'
                >
                  NO CREDIT CARD REQUIRED
                </Typography>
              </Box>
            </Box>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          flexWrap='wrap'
          justifyContent={'center'}
        >
          <Box
            display='flex'
            flexWrap='wrap'
            justifyContent={'space-between'}
            padding={{ xs: 2, sm: 4, md: 6 }}
          >
            <Partners />
          </Box>
        </Grid>
        <Grid
          item
          xs={11}
          display='flex'
          flexWrap='wrap'
          justifyContent={'center'}
        >
          <Image
            priority
            src='/new-mangomap-assets/images/mangoGIS.png'
            alt='Mango - GIS mapping, made simple.'
            width={1079}
            height={700}
            style={{
              maxWidth: '100%',
              height: 'auto',
              filter: 'drop-shadow(0px 10px 20px rgba(0,0,0,0.2))'
            }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Hero
