import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import WithLayout from '~gis-website/WithLayout'
import Container from '~gis-website/common/Container'
import Main from '~gis-website/layouts/Main'
import StateProvider from '~gis-website/states/provider'
import {
  AddOns,
  CTAReviews,
  Features,
  Hero,
  Reviews,
  Services
} from './components'

const Homepage = () => {
  return (
    <StateProvider>
      <WithLayout
        component={App}
        layout={Main}
      />
    </StateProvider>
  )
}

const App = () => {
  const theme = useTheme()

  return (
    <Box id='home'>
      <Box
        maxWidth={'100%'}
        sx={{
          marginTop: '-100px',
          backgroundImage:
            'url("./new-mangomap-assets/images/topographic.jpg"),url("./new-mangomap-assets/images/stipple_1.webp")',
          backgroundBlendMode: 'screen',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'grayscale(1)',
          width: '100vw',
          height: 'calc(100vh + 100px)',
          position: 'fixed',
          opacity: '10%',
          zIndex: '0'
        }}
      />
      <Box
        position={'relative'}
        minHeight={450}
        overflow={'hidden'}
      >
        <Container
          position={'relative'}
          zIndex={2}
        >
          <Hero />
        </Container>
      </Box>
      <Container minHeight={{ xs: 'auto', md: '100vh' }}>
        <Reviews />
      </Container>
      <Container minHeight={{ xs: 'auto', md: '100vh' }}>
        <Services />
      </Container>
      <Container minHeight={{ xs: 'auto', md: '100vh' }}>
        <AddOns />
      </Container>
      <Container minHeight={{ xs: 'auto', md: '100vh' }}>
        <Features />
      </Container>

      <Box
        minHeight={{ xs: 'auto', md: '100vh' }}
        height={'auto'}
        position={'relative'}
        sx={{
          backgroundImage:
            'url("./new-mangomap-assets/images/topographic.jpg"), url("./new-mangomap-assets/images/stipple_2.webp")',
          backgroundBlendMode: 'multiply',
          backgroundSize: 'cover',
          backgroundPosition: 'center right',
          backgroundAttachment: 'fixed',
          '&:after': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            content: '" "',
            zIndex: 1,
            backgroundColor: theme.palette.primary.dark,
            backgroundAttachment: 'fixed',
            opacity: 0.9
          }
        }}
      >
        <Box
          position={{ xs: 'relative', md: 'absolute' }}
          top={{ xs: 0, md: '50%' }}
          width={'100%'}
          height={'auto'}
          sx={{
            transform: { md: 'translateY(-50%)' },
            opacity: '1'
          }}
          zIndex={2}
        >
          <Container>
            <CTAReviews />
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export default Homepage
